.button-call {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    padding: 4px 24px;
    border: 1px solid #fe0d05;
    border-radius: 8px;
        background: #ff1006;
    background: -webkit-gradient(linear, left top, left bottom, from(#ff1006), to(#cd0d0b));
    background: -moz-linear-gradient(top, #ff1006, #cd0d0b);
    background: linear-gradient(to bottom, #ff1006, #cd0d0b);
    text-shadow: #591717 1px 3px 1px;
    font: normal normal bold 20px verdana;
    color: #ffffff;
    text-decoration: none;
    margin-bottom:5px;
    width: 95%;
    height: 60px;
}
.button-call:hover,
.button-call:focus {
    border: 1px solid #ff1508;
     background: #fe0d05;
    background: -webkit-gradient(linear, left top, left bottom, from(#fe0d05), to(#ab0b09));
    background: -moz-linear-gradient(top, #fe0d05, #ab0b09);
    background: linear-gradient(to bottom, #fe0d05, #ab0b09);
    color: #ffffff;
    text-decoration: none;
}
.button-call:active {
    background: #980803;
    background: -webkit-gradient(linear, left top, left bottom, from(#980803), to(#ab0b09));
    background: -moz-linear-gradient(top, #980803, #ab0b09);
    background: linear-gradient(to bottom, #980803, #ab0b09);
}


.button-call2 {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    padding: 4px 24px;
    border: 1px solid #fe0d05;
    border-radius: 8px;
        background: #ff1006;
    background: -webkit-gradient(linear, left top, left bottom, from(#ff1006), to(#cd0d0b));
    background: -moz-linear-gradient(top, #ff1006, #cd0d0b);
    background: linear-gradient(to bottom, #ff1006, #cd0d0b);
    text-shadow: #591717 1px 3px 1px;
    font: normal normal bold 20px verdana;
    color: #ffffff;
    text-decoration: none;
    margin-bottom:5px;
    width: 95%;
    height: 36px;
}
.button-call2:hover,
.button-call2:focus {
    border: 1px solid #ff1508;
     background: #fe0d05;
    background: -webkit-gradient(linear, left top, left bottom, from(#fe0d05), to(#ab0b09));
    background: -moz-linear-gradient(top, #fe0d05, #ab0b09);
    background: linear-gradient(to bottom, #fe0d05, #ab0b09);
    color: #ffffff;
    text-decoration: none;
}
.button-call2:active {
    background: #980803;
    background: -webkit-gradient(linear, left top, left bottom, from(#980803), to(#ab0b09));
    background: -moz-linear-gradient(top, #980803, #ab0b09);
    background: linear-gradient(to bottom, #980803, #ab0b09);
}






	
.dropdown-menu {
	min-width: 200px;
}
.dropdown-menu.columns-2 {
	min-width: 450px;
}
.dropdown-menu.columns-3 {
	min-width: 600px;
}
.dropdown-menu li a {
	padding: 5px 15px;
	font-weight: 300;
}
.multi-column-dropdown {
	list-style: none;
   padding: 0 ;
   margin: 0 5px;
}
.multi-column-dropdown li a {
	display: block;
	clear: both;
	line-height: 1.428571429;
	color: @navbar-default-link-color;
	white-space: normal;
}
.multi-column-dropdown li a:hover {
	text-decoration: none;
	color: #262626;
	background-color: #f5f5f5;
}

@media (max-width: 767px) {
	.dropdown-menu.multi-column {
		min-width: 240px !important;
		overflow-x: hidden;
	}
}



.marquee {
	background-color: #fff;
	
  }


  
  
 .button {
    display: inline-block;
	margin-top: 20px;
    text-align: center;
    vertical-align: middle;
    padding: 6px 22px;
    border: 2px solid @brand-primary;
    border-radius: 10px;
    background: @footer; /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(left top, @footer, #fff); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(bottom right, @footer, #fff); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(bottom right, @footer, #fff); /* For Firefox 3.6 to 15 */
    background: linear-gradient(to bottom right, @footer, #fff); /* Standard syntax */
    color: @text-color;
    text-decoration: none;
	
		 
	 transition: all 0.2s;
 	 -webkit-transition: all 0.2s;
 	 &:hover {
   	 box-shadow: 0 2px 0 @brand-primary;
     transform: translate(0, -2px);
    -ms-transform: translate(0, -2px);
    -webkit-transform: translate(0, -2px);
  }
}
.button:hover,
.button:focus {
    background: @footer; /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(left top, darken(@body-bg, 30%), #fff); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(bottom right, darken(@body-bg, 30%), #fff); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(bottom right, darken(@body-bg, 30%), #fff); /* For Firefox 3.6 to 15 */
    background: linear-gradient(to bottom right, darken(@body-bg, 30%), #fff); /* Standard syntax */
    color: @brand-primary;
    text-decoration: none;
}


 

.raise-img img {
     background-color: rgba(0,0,0,0);
     transition: all 0.2s;
 	 -webkit-transition: all 0.2s;
 	 border-radius: 6px;
 	 &:hover {
   	 box-shadow: 0 4px 0 @brand-primary;
     transform: translate(0, -4px);
    -ms-transform: translate(0, -4px);
    -webkit-transform: translate(0, -4px);
  }
  
  @media only screen and (max-width: 776px) {
      margin-bottom: 10px;
  }
}

.raise-img img:hover {
	bottom: 5px;
}

.home-contact i {
	margin-left: 10px;
}
    #owl-demo .item{
      margin: 3px;
    }
    #owl-demo .item img{
      display: block;
      width: 100%;
      height: auto;
    }

@media only screen and (max-width: 1000px) {
	.ipad-nav {
				font-size: 12px !important;
			}
}
.top {
	position: relative;
	width:100%;
	background: @header;
	padding-top: 20px;
}
.divider {
	position: relative;
	width:100%;
	height:10px;
	background-color: @divider;
	-webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5), 0 0 40px rgba(0, 0, 0, 0.1) inset;
           -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5), 0 0 40px rgba(0, 0, 0, 0.1) inset;
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.divider:before,
.divider:after {
	content:"";
    position:absolute;
    z-index:-1;
    -webkit-box-shadow:0 0 20px rgba(0,0,0,0.8);
    -moz-box-shadow:0 0 20px rgba(0,0,0,0.8);
    box-shadow:0 0 20px rgba(0,0,0,0.8);
    top:50%;
    bottom:0;
    left:10px;
    right:10px;
    -moz-border-radius:100px / 10px;
    border-radius:100px / 10px;
}
.divider:after {
	right:10px;
    left:auto;
    -webkit-transform:skew(8deg) rotate(3deg);
       -moz-transform:skew(8deg) rotate(3deg);
        -ms-transform:skew(8deg) rotate(3deg);
         -o-transform:skew(8deg) rotate(3deg);
            transform:skew(8deg) rotate(3deg);
}
.logo {
	position: relative;
	padding-bottom: 0px;
	z-index: 99;
	
	@media only screen and (max-width: 767px) {
        padding-left: 0;
        padding-right: 0;
        
        img {
            padding: 0 5px;
        }
	}
}

@media only screen and (max-width: 767px) {
    .padding-mobile {
        padding: 0;
    }
}
.logo-text {
	padding-top: 22px;
	position: absolute;
	top: 0;
	left: 15px;
}
.slogan h3 {
	color: @body-bg;
}
.main-content {
	margin-top: 15px;
}
h1 {
	margin-bottom:15px;
}	
.middle-content {
	width: 100%;
	margin: 40px 0;
	background: @brand-primary;
	border-top:3px solid white;
	border-bottom:3px solid white;

	h2, a {
		color: @headings-alt;
	}
}
.bottom-content {
	margin-bottom: 40px;
}
.button-clear {
	width: 150px;
	height: 30px;
	border: 1px solid #fff;
	padding-top: 5px;
	margin: 0 auto 20px auto;
	border-radius: 10px;
}
.bullet-bg {
	background-color: @brand-primary;
	border-radius: 10px;
	padding:10px;
	
	li {
		margin-left:30px;
		color: #fff;
	}
	}
.footer-bg {
	width: 100%;
	min-height: 100px;
	background: @footer;
	box-shadow: 0 0 10px 1px lighten(@footer, 15%);
	padding: 20px 0;
	margin-top: 40px;
	color: @footer-text;
}
.bottom-bar {
	width: 100%;
	height: 8px;
	background-color: @brand-primary;
}
.copyright {
	padding-top: 30px;
}
.lime {
	padding-top: 10px;
}
.top-img {
	padding-bottom: 20px;
}
.acceditation {
	img {
		padding-left: 20px;
	
	}
}

.contact {
	text-align: center;
}

@media screen and (min-width: @screen-sm-min) {
	.contact {
		text-align: right;
	}
}

#area-map,
#area-map-small {
	margin-top: 30px;
}

.subtitle-pge {
	border-bottom: 1px solid #CBCBCB;
	border-top: 1px solid #CBCBCB;
	line-height: 1.4;
	margin-bottom: 25px;
	padding-bottom: 25px;
	margin-top: 25px;
	padding-top: 25px;
	font-weight: 200;
	text-align: center;
}

.get {
    display:none;
}

