
// Customization

@review-bg: @brand-primary;
@review-color: white;
@review-min-height: 180px;
//----------



.review-slider, .review-slides2, .review-slides3, .review-slides4 {
    overflow: visible !important;
    min-height: @review-min-height;
}

.review-slides, .review-slides2, .review-slides3, .review-slides4 {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
    color: @review-color;
    height: auto;
    display: block;


    .review-slide {
        background-color: @review-bg !important;
        padding: 30px 30px 20px 60px;
        border-radius: 5px;
        position: relative;
        width: 100%;


        &:after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            left: 30px;
            right: auto;
            bottom: -20px;
            border-left: 32px solid transparent;
            border-right: 32px solid transparent;
            border-top: 32px solid @review-bg;
        }

        span {
            //display: block;
            float: right;
            //margin-top: 15px;
            margin-right: 5px;
            color: white;
            font-size: 70px;
            font-family: 'Comic Sans';
            margin-bottom: -50px;
        }
    }

    li:before {
        content: '“';
        font-size: 70px;
        position: absolute;
        top: 10px;
        left: 20px;
        font-family: 'Comic Sans';
    }
}
#review-slider {
    height: auto;
    background-color: @review-bg !important;
}

.gallery {
    img {
        @media only screen and (max-width: 420px) {
            height: 90px;
            width: 90px;
        }
    }
}